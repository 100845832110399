import { Link } from "gatsby";
import React from "react";

type SpLinkType = {
  path: string;
  target?: string;
  [key: string]: any;
};

const SpLink: React.FC<SpLinkType> = ({ path, target, children, ...rest }) => {
  const link = {
    internal: (
      <Link to={path} {...rest}>
        {children}
      </Link>
    ),
    external: (
      <a href={path} target={target} rel="noreferrer noopener" {...rest}>
        {children}
      </a>
    ),
  };

  return !target ? link.internal : link.external;
};

export default SpLink;
