import React, { useEffect, useState } from "react";
import { BuilderComponent } from "@builder.io/react";
import { graphql, HeadProps, PageProps } from "gatsby";
import SEOMeta from "@components/SEOMeta";
import { RegisterComponentPageBlank } from "@utils/RegisterComponentPageBlank";
import { MainProvider } from "@contexts/MainContext";
import useGtmPageChange from "@hooks/useGtmPageChange";
import generateUuId from "@utils/generateUuId";
import { mxpPageViewed } from "@utils/mixpanelEvents/mixpanelEvents";
import "../../libs/builder.setting";
import { enableSmoothScroll } from "@utils/enableSmoothScroll";
import withCalculatorProvider from "@components/HOC/withCalculatorProvider";

type DataProps = {
  allBuilderModels: Queries.builder;
};

const PageTemplate: React.FC<PageProps<DataProps>> = ({ data }) => {
  const content = data?.allBuilderModels.onePage?.content;

  const uuid = generateUuId();

  useEffect(() => {
    setIsClient(true);
    mxpPageViewed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGtmPageChange(uuid);

  const [isClient, setIsClient] = useState(false);

  const isScrollable = content?.data?.useScroll;
  enableSmoothScroll(isScrollable);

  return (
    <MainProvider>
      <React.Fragment key={`layout-${isClient}`}>
        <div>
          <main>
            {content && <BuilderComponent content={content} model="page" />}
          </main>
        </div>
      </React.Fragment>
    </MainProvider>
  );
};

export default PageTemplate;

RegisterComponentPageBlank();

export const Head: React.FC<HeadProps<DataProps>> = ({ data }) => {
  const {
    allBuilderModels: { onePage },
  } = data;

  const seoData = onePage?.content?.data as Queries.builder_Page;

  return (
    <>
      <SEOMeta
        index={seoData?.index!}
        title={seoData?.title!}
        description={seoData?.description!}
      />
    </>
  );
};

export const query = graphql`
  query ($path: String!) {
    allBuilderModels {
      onePage(
        target: { urlPath: $path }
        options: { cachebust: false, includeRefs: true }
      ) {
        content
      }
    }
  }
`;
