export const SECURE_SIMPLEPRACTICE_SIGNIN =
  "https://secure.simplepractice.com/users/sign_in";

export const featuredMenuItem = {
  path: "",
  menuItemImage: {
    altText: "",
    mediaItemUrl: "",
  },
  description: "",
  label: "",
  ctaLabel: "",
};

export const SECURE_URL_SESSION_STORAGE = "secure-url";
